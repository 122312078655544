import { FieldMsg } from "./../../../core/service/field-msg.service";
import { TicketNewService } from "src/app/ticket-new/ticket.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Subscription } from "rxjs";
import { TicketModel } from "src/app/ticket/ticket.model";
import { TicketService } from "src/app/ticket/ticket.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/core/service/shared.service";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import { environment } from "src/environments/environment";

import { NgOtpInputComponent, NgOtpInputConfig } from "ng-otp-input";
import { ChangeDetectionStrategy } from "@angular/core";
import Swal from "sweetalert2";

export interface ChipColor {
  name: string;
  color: string;
}

export interface Tag {
  name: string;
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-consumable-job-card",
  templateUrl: "./consumable-job-card.component.html",
  styleUrls: ["./consumable-job-card.component.css"],
})
export class ConsumableJobCardComponent implements OnInit {
  [x: string]: any;
  public Editor = ClassicEditor;
  base64File: string = null;
  filename: string = null;
  tabs = ["First", "Second", "Third"];
  action: string;
  dialogTitle: string;
  filterData: any;
  isDetails = false;
  selectedUser: any;
  eqpModel: any;
  statusHistory: any;
  reqCalibrationStatusHistory: any;
  reqCalibrationsubStatusHistory: any;
  substatusHistory: any;
  assetMaterialList: any = [];
  ticketTimeLineData: any = [];
  fileValidation: boolean = false;
  mobTabHide: boolean = false;
  filteredOptions: Observable<string[]>;
  consumableJobCardForm: FormGroup;
  materialForm: FormGroup;
  isEditFilter = false;
  ticketData: any;
  myControl = new FormControl("");
  gNo: string;
  isProxyAllowed: boolean;
  public entityFilterCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  isSubmitted: boolean = false;
  statusDisable: boolean = false;
  lineDataDisable: boolean = false;
    commentHide: boolean = false;
  resolveVal: boolean = false;
  reqCalRecommend: boolean = false;
  apicallSub?: Subscription;
  public referenceTicketList: any = [];
  visible = true;
  selectable = true;
  removable = true;
  projectCode: any;
  ticketCode: any;
  hapAssetType: any;
  resolveStatus: any;
  resolveSubStatus: any;
  hubCode: any;
  tagCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  currentDate: any;
  @ViewChild("tagInput", { static: true })
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput: NgOtpInputComponent;
  actionTakeList: any = [];

  @ViewChild("priority", { static: false }) priorityField: any;
  @ViewChild("myInput", { static: true }) myInput: ElementRef =
    Object.create(null);
  routerUrl: any;
  currentTime: any;
  actionTaken: any;
  searchCriteria: any;
  statusList: any = [];
  reasonList: any = [];
  recommendReasonList: any = [];
  calbirationReasonList: any = [];
  employeeList: any = [];
  natureOfComplaintList: any = [];
  equipNumberList: any = [];
  dateTime: any;
  dropdownSettings: any = {};
  dropdownActionSettings: any = {};
  complaintList: any = [];
  complaintInActiveList: any = [];
  complainInActive: any = [];
  deSelectList: any = [];
  ActionList: any = [];
  isDropdownDisabled: boolean = false;
  scrapRecommend: boolean = false;
  actionTakenVal: boolean = false;
  // resolvedVal: boolean = false;
  jobAttendedValidator: boolean = false;
  isPopupVisible: boolean = false;
  titleText: any;
  happyCode: any = "";
  ticketStatus: any;
  position: any;
  searchTicketStatus: any;
  calInprogressStatusHistory: any;
  calInprogresssubStatusHistory: any;
  calCompletedStatusHistory: any;
  calCompletedSubStatusHistory: any;
  partReplacementStatusHistory: any;
  partReplacementSubStatusHistory: any;
  deviceInstallStatusHistory: any;
  deviceInstallSubStatusHistory: any;
  deviceReplacementStatusHistory: any;
  deviceReplacementSubStatusHistory: any;
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: "",
  };

  uomList: any = [
    {
      uomName: "Ml",
      uomCode: "ml",
    },
    {
      uomName: "Ltr",
      uomCode: "ltr",
    },
  ];
  panelOpenState = false;
  hapHappyCode: any = "";
  currentUser: any;
  roleCheck: any;
  userCode: any;
  UserRoles: any = environment?.roles;
  hapCodeValid: boolean = false;
  roleDisable: boolean = false;
  hapCodeRequired: boolean = true;
  isDropdownDisabled1: boolean = true;
  statusDropdownDisable: boolean = true;
  printHide: boolean = true;
  /* auto search */

  constructor(
    public dialogRef: MatDialogRef<ConsumableJobCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ticketService: TicketNewService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    public fieldMsg: FieldMsg,
    
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.roleCheck = this.currentUser.data[0].roles;
    this.userCode = this.currentUser.data[0].userInfo.userCode;
    this.routerUrl = this.sharedService.routerUrl();
    this.action = data.action;
    this.ticketData = data.jobCard.data;
    console.log("this.ticketData", this.ticketData);
    console.log("plantcodedede", this.ticketData.plantCode);
    this.hapAssetType = this.ticketData.hapAssetType;
    this.ticketCode = this.ticketData.ticketCode;
    this.hubCode = this.ticketData.hapHubCode;
    this.currentDate = new Date().toISOString().slice(0, 10);
    var momentObj = moment(this.currentDate, "YYYY-MM-DDLT");
    this.dateTime = momentObj.format("DD-MM-YYYY");

    this.consumableJobCardForm = this.createJobCardForm();
    this.dialogTitle = `Job Card :: Ticket No - ${this.ticketCode} :: Status - ${this.ticketData.ticketCurrentStatus}`;

    this.titleText = `Verify HAP Code :: ${this.ticketCode}`;
    this.hapHappyCode = this.ticketData.hapHappyCode;
    this.ticketStatus = this.ticketData.ticketCurrentStatus;

    this.disableFields();
    /*if(this.ticketData.ticketStatusCode === 15 && this.ticketData.ticketSubStatusCode === 34){
    this.loadReqcalReason();
  this.reqCalRecommend = true;
}else{
    this.reqCalRecommend = false;
}*/

    if (this.ticketStatus == "Resolved") {
      this.consumableJobCardForm.disable();
      this.isDropdownDisabled = true;
    } else {
      this.isDropdownDisabled = false;
    }
  }
  ngOnDestroy(): void {}

  ngOnInit() {
    this.dropdownSettings = {
      idField: "compliantName",
      textField: "compliantName",
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    //ACtion taken
    this.dropdownActionSettings = {
      idField: "reasonName",
      textField: "reasonName",
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.hubEmployeeList();
    this.getHAPStatus(this.ticketData.projectCode, this.ticketData.moduleCode);
    this.getComplaintByAsset(
      this.ticketData.hapAssetCode,
      this.ticketData.hapDeptCode
    );
    this.getTicketAssetComplaints();
    this.getJobCardData();
    this.getCurrentLocation();
    this.getEquipment(
      this.ticketData.hapAssetCode,
      this.ticketData.hapDeptCode
    );

    this.roleDisableVal();
    this.loadReason();
    this.loadRecommendReason();
    this.ticketAssetMaterial();
    this.getTicketHistory(this.ticketCode);
  }

  createJobCardForm(): FormGroup {
    return this.fb.group({
      currentDateTime: [
        {
          value: this.dateTime,
          disabled: true,
        },
      ],
      assetSticker: [
        {
          value: "",
          disabled: true,
        },
      ],
      dataSend: [],
      scrapRequestRemarks: [],
      category: [],
      calibrationRemarks: [],
      equipNo: [
        {
          value: "",
          disabled: true,
        },
      ],
      actionTake: [
        {
          value: "",
          disabled: true,
        },
      ],
        comments: [
            {
                value: "",
                disabled: true,
            },
        ],
      assetNo: [
        {
          value: "",
          disabled: true,
        },
      ],
      busTicket: [
        {
          value: "",
          disabled: false,
        },
      ],
      transportMode: [
        {
          value: "",
          disabled: false,
        },
      ],
      centerName: [
        {
          value: "",
          disabled: true,
        },
      ],
      chillingCenter: [
        {
          value: "",
          disabled: true,
        },
      ],
      completed: [],
      equipmentModel: [
        {
          value: "",
          disabled: true,
        },
      ],
      equipmentName: [
        {
          value: "",
          disabled: true,
        },
      ],
      equipmentSerialNo: [
        {
          value: "",
          disabled: true,
        },
      ],
      feedApk: [],
      hmbCode: [
        {
          value: "",
          disabled: true,
        },
      ],
      hologramStickerAvailable: [
        {
          value: "",
          disabled: true,
        },
      ],
      jobCardNo: [
        {
          value: "",
          disabled: true,
        },
      ],
      issuedTo: [
        {
          value: "",
          disabled: true,
        },
      ],
      jobAttended: [
        {
          value: "",
        },
      ],
      jobCompleted: [
        {
          value: "",
          disabled: true,
        },
      ],
      mobileNumber: [
        {
          value: "",
          disabled: true,
        },
        [Validators.pattern("[6-9]\\d{9}")],
      ],
      natureOfComplaint: [],
      plantCode: [
        {
          value: "",
          disabled: true,
        },
      ],
      rdeCallAttend: [],
      receivedFrom: [
        {
          value: "",
          disabled: true,
        },
      ],
      receiverName: [],
      simNumber: [
        {
          value: "-",
          disabled: true,
        },
      ],
      tabApkVersion: [
        {
          value: "-",
          disabled: true,
        },
      ],
      jobCompilationDateTime: [
        {
          value: "",
          disabled: true,
        },
      ],
      ticketStatusCode: [
        {
          ticketStatusCode: this.ticketData.ticketStatusCode,
          ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
          ticketStatusName: this.ticketData.statusName,
        },
      ],
    });
  }

  get f() {
    return this.consumableJobCardForm.controls;
  }
  disableFields() {
    console.log(
      "this.ticketData.ticketStatusCode",
      this.ticketData.ticketStatusCode
    );
    console.log(
      "this.ticketData.ticketSubStatusCode",
      this.ticketData.ticketSubStatusCode
    );
      console.log(
          "consumable jobcardd"
      );
      //comment hide if resolved saved
      if(this.ticketData.ticketStatusCode === 7 &&  this.ticketData.ticketSubStatusCode === 7){
          console.log(
              "consumable jobcardd inside"
          );
          this.commentHide = false;
      }else{
          this.commentHide = true;
      }
    //Scrap update Disabled
    if (
      (this.ticketData.ticketStatusCode === 17 &&
        this.ticketData.ticketSubStatusCode === 26) ||
      (this.ticketData.ticketStatusCode === 9 &&
        this.ticketData.ticketSubStatusCode === 9)
    ) {
      this.lineDataDisable = true;
    } else {
      this.lineDataDisable = false;
    }
    //
    if (
      this.ticketData.jobAttended === null ||
      this.ticketData.jobAttended === ""
    ) {
      this.isDropdownDisabled1 = true;
      this.printHide = false;
    } else {
      this.isDropdownDisabled1 = false;
      this.printHide = true;
    }
  }
  detailsUser(content) {
    this.modalService.open(content, { size: "xl" });
  }

  onNoClick() {
    this.dialogRef.close({ action: "close" });
  }
  closePopup() {
    this.happyCode = "";
    this.hapCodeValid = false;
    this.isPopupVisible = false;
    this.ngOtpInput.setValue("");
  }
  async loadReason() {
    this.reasonList = [];
    let catId = 5;
    this.searchCriteria = {
      categoryId: catId,
    };
    try {
      const response = await this.ticketService.getReasonList(
        this.searchCriteria
      );
      this.reasonList = response.body.data || [];
    } catch (e) {
      this.reasonList = [];
    }
  }

  async loadRecommendReason() {
    this.recommendReasonList = [];
    let catId = 13;
    this.searchCriteria = {
      categoryId: catId,
    };
    try {
      const response = await this.ticketService.getReasonList(
        this.searchCriteria
      );
      let data = response.body.data || [];
      this.recommendReasonList = data.filter(
        (item) => item.roleName === this.roleCheck[0]
      );
    } catch (e) {
      this.recommendReasonList = [];
    }
  }
  //Remark for Req Calibration
  async loadReqcalReason() {
    let calRemarks;
    if (this.ticketData.hapAssetName === "ANALYZER") {
      calRemarks = 18;
    }
    if (this.ticketData.hapAssetName === "WEIGHSCALE") {
      calRemarks = 19;
    }
    console.log("reqCal Reason", calRemarks);
    this.searchCriteria = {
      categoryId: calRemarks,
    };
    try {
      const response = await this.ticketService.getReasonList(
        this.searchCriteria
      );
      let data = response.body.data || [];
      this.calbirationReasonList = data.filter(
        (item) => item.roleName === this.roleCheck[0]
      );
      console.log(
        "reqCal this.calbirationReasonList",
        this.calbirationReasonList
      );
    } catch (e) {
      this.calbirationReasonList = [];
    }
  }

  public roleDisableVal() {
    if (this.roleCheck[0] === "ROLE_HUB_INCHARGE") {
      if (this.ticketStatus == "Resolved") {
        // this.jobCardForm.get("equipmentSerialNo").disable();
        //    this.jobCardForm.get("issuedTo").disable();
        //    this.jobCardForm.get("mobileNumber").disable();
        //   this.jobCardForm.get("jobAttended").disable();
        // this.jobCardForm.get("actionTake").disable();
        this.consumableJobCardForm.get("feedApk").disable();
        this.consumableJobCardForm.get("rdeCallAttend").disable();
        this.consumableJobCardForm.get("dataSend").disable();
      } else {
        //  this.jobCardForm.get("equipmentSerialNo").enable();
        //   this.jobCardForm.get("issuedTo").enable();
        //   this.jobCardForm.get("mobileNumber").enable();
        //   this.jobCardForm.get("jobAttended").enable();
        //  this.jobCardForm.get("actionTake").enable();
        this.consumableJobCardForm.get("feedApk").enable();
        this.consumableJobCardForm.get("rdeCallAttend").enable();
        this.consumableJobCardForm.get("dataSend").enable();
      }
    } else {
      // this.jobCardForm.get("equipmentSerialNo").disable();
      //   this.jobCardForm.get("issuedTo").disable();
      //   this.jobCardForm.get("mobileNumber").disable();
      //   this.jobCardForm.get("jobAttended").disable();
      // this.jobCardForm.get("actionTake").disable();
      this.consumableJobCardForm.get("feedApk").disable();
      this.consumableJobCardForm.get("rdeCallAttend").disable();
      this.consumableJobCardForm.get("dataSend").disable();
    }
  }
  async downloadPDF() {
    this.spinner.show();
    try {
      let resObj = {
        jobCardNo: this.consumableJobCardForm.get("jobCardNo").value,
        ticketCode: this.ticketCode,
      };
      const response = await this.ticketService.generateJobCardData(resObj);

      const file = new Blob([response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = `Job_Card_${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}.pdf`;
      link.click();

      this.spinner.hide();
    } catch (e) {
      this.spinner.hide();
    }
  }
  async getJobCardData() {
    let ticketCode = this.ticketCode;
    let resObj = { ticketCode };
    const response = await this.ticketService.getJobCardData(resObj);

    let assetIntakeDetails = response?.body?.data[0].assetIntake || [];
    let jobCardDetails = response?.body?.data[0].jobCards || [];
    let assetTravelDetails = response?.body?.data[0].assetTravel || [];
    console.log("assetIntakeDetails", assetIntakeDetails);
    console.log("jobCardDetails", jobCardDetails);
    console.log("assetTravelDetails", assetTravelDetails);
    let usingActionSplit;
    let actionTakenSplit;

    if (jobCardDetails.length > 0) {
      usingActionSplit = jobCardDetails[0].actionTake
        ? jobCardDetails[0].actionTake?.split(",")
        : [];
      let data;
      for (let i = 0; i < usingActionSplit.length; i++) {
        data = {
          id: usingActionSplit[i],
        };
      }
      this.actionTakeList.push(data);

      if (usingActionSplit[0] === "" || usingActionSplit[0] === null) {
        actionTakenSplit = null;
      } else {
        actionTakenSplit = usingActionSplit;
      }
    }
    console.log("Line item Data", this.ticketData);
    console.log("SAP Code", this.ticketData.hapHmbCode);
    console.log("CENTER Code", this.ticketData.hmbName);
    console.log("chillingCenter Code", this.ticketData.plantName);
    this.consumableJobCardForm.patchValue({
      hmbCode: this.ticketData.hapHmbCode ? this.ticketData.hapHmbCode : "",
      centerName: this.ticketData.hmbName ? this.ticketData.hmbName : "",
      chillingCenter: this.ticketData.plantName
        ? this.ticketData.plantName
        : "",
      plantName: this.ticketData.plantName ? this.ticketData.plantName : "",
      assetNo: this.ticketData.hapAssetCode ? this.ticketData.hapAssetCode : "",
      hologramStickerAvailable:
        assetIntakeDetails.length > 0
          ? assetIntakeDetails[0].hologramSticketAvailable
          : "",
      assetSticker:
        assetIntakeDetails.length > 0
          ? assetIntakeDetails[0].assetStickerAvailable
          : "",

      busTicket:
        assetTravelDetails.length > 0
          ? assetTravelDetails[0].modeOfTransport == "Bus"
            ? "1"
            : "2"
          : "",
      transportMode:
        assetTravelDetails.length > 0
          ? assetTravelDetails[0].modeOfTransport
          : "",
      receivedFrom:
        assetTravelDetails.length > 0 ? assetTravelDetails[0].personName : "",
      receiverName:
        assetTravelDetails.length > 0 ? assetTravelDetails[0].personName : "",

      jobCardNo: jobCardDetails.length > 0 ? jobCardDetails[0].idJobCardNo : "",
      jobAttended:
        jobCardDetails.length > 0 ? jobCardDetails[0].jobAttended : "",
      mobileNumber:
        jobCardDetails.length > 0 ? jobCardDetails[0].mobileNumber : "",

      actionTake: actionTakenSplit,
      jobCompleted:
        jobCardDetails.length > 0 ? jobCardDetails[0].jobCompleted : "",
      jobCompilationDateTime:
        jobCardDetails.length > 0
          ? jobCardDetails[0].completionDate
            ? moment(
                jobCardDetails[0].completionDate +
                  " " +
                  jobCardDetails[0].completionTime,
                "YYYY-MM-DD HH:mm:ss"
              ).format("DD-MM-YYYY hh:mm A")
            : "-"
          : "-",
      feedApk:
        jobCardDetails.length > 0
          ? jobCardDetails[0].feedApk == "Y"
            ? "1"
            : "2"
          : "",
      rdeCallAttend:
        jobCardDetails.length > 0
          ? jobCardDetails[0].rdeCallAttend == "Y"
            ? "1"
            : "2"
          : "",
      dataSend:
        jobCardDetails.length > 0
          ? jobCardDetails[0].dataSend == "Y"
            ? "1"
            : "2"
          : "",
      issuedTo: jobCardDetails.length > 0 ? jobCardDetails[0].issuedTo : "",

      // natureOfComplaint: jobCardDetails[0].natureOfComplaint,
    });

    /*let natureOfComplaint = jobCardDetails[0].natureOfComplaint;
      console.log("natureOfComplaint>>>>>>>",natureOfComplaint)
      this.jobCardForm.patchValue({
          natureOfComplaint: natureOfComplaint,
      });*/
  }
  async createJob() {
    if (
      this.ticketData.plantCode == "" ||
      this.ticketData.plantCode == null ||
      this.ticketData.plantCode == undefined
    ) {
      Swal.fire({
        title: "Information",
        text: "JobCard Cannot be Create Without Plant",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
      });
    } else {
      //req for Calibration
      let formObj = this.consumableJobCardForm.getRawValue();
      /* if (formObj.ticketStatusCode.statusCode === 15 && formObj.ticketStatusCode.subStatusCode === 34) {
              this.isSubmitted = true;
              await this.getCurrentLocation();
              if (this.position) {

                  let natureOfComplaintData = this.complaintList.map(
                      (a) => a.complaintName
                  );

                  let actionTake;
                  let actionTakenList = formObj;
                  if (actionTakenList.actionTake === undefined || actionTakenList.actionTake === ""
                      || (actionTakenList.actionTake === null)) {
                      actionTake = ""
                  } else {
                      let actionTakeCheck = actionTakenList.actionTake.map(
                          (a) => a.reasonName
                      );
                      if (actionTakeCheck[0] === undefined || actionTakeCheck[0] === "" ||
                          (actionTakeCheck[0] === null)) {
                          actionTake = actionTakenList.actionTake.map(
                              (a) => a
                          );
                      } else {
                          actionTake = actionTakenList.actionTake.map(
                              (a) => a.reasonName
                          );

                      }
                  }
                  let jobCardDetails;

                  if ((formObj.ticketStatusCode.statusCode === 11 && formObj.ticketStatusCode.subStatusCode === 11) ||
                      (formObj.ticketStatusCode.statusCode === 15 && formObj.ticketStatusCode.subStatusCode === 34)) {
                      jobCardDetails =
                          {
                              aasetSticket: formObj.assetSticker ? formObj.assetSticker : "",
                              actionTake: actionTake
                                  ? actionTake.toString()
                                  : "",
                              //actionTake: this.actionTakeList.map((x) => x.id).join(", "),
                              assetNo: formObj.assetNo ? formObj.assetNo : "",
                              busTicket: formObj.busTicket == "1" ? "Y" : "N",
                              centerName: formObj.centerName ? formObj.centerName : "",
                              chillingCenter: formObj.chillingCenter ? formObj.chillingCenter : "",

                              dataSend: formObj.dataSend
                                  ? formObj.dataSend == "1"
                                      ? "Y"
                                      : "N"
                                  : "",
                              equipmentModel: formObj.equipmentModel ? formObj.equipmentModel : "",
                              equipmentName: formObj.equipmentName ? formObj.equipmentName : "",
                              equipmentSerialNo: formObj.equipmentSerialNo
                                  ? formObj.equipmentSerialNo
                                  : "",
                              feedApk: formObj.feedApk ? (formObj.feedApk == "1" ? "Y" : "N") : "",
                              hmbCode: formObj.hmbCode ? formObj.hmbCode : "",
                              hologramStickerAvailable: formObj.hologramStickerAvailable
                                  ? formObj.hologramStickerAvailable
                                  : "",
                              idJobCardNo: formObj.jobCardNo ? formObj.jobCardNo : "",
                              idTicketCode: this.ticketCode,
                              issuedTo: formObj.issuedTo ? formObj.issuedTo : "",
                              jobAttended: formObj.jobAttended ? formObj.jobAttended : 0,
                              jobCompleted: formObj.jobCompleted ? formObj.jobCompleted : 0,
                              mobileNumber: formObj.mobileNumber ? formObj.mobileNumber : "",
                              natureOfComplaint: natureOfComplaintData
                                  ? natureOfComplaintData.toString()
                                  : "",
                              plantCode: this.ticketData.plantCode ? this.ticketData.plantCode : "",
                              rdeCallAttend: formObj.rdeCallAttend
                                  ? formObj.rdeCallAttend == "1"
                                      ? "Y"
                                      : "N"
                                  : "",
                              receivedFrom: formObj.receivedFrom ? formObj.receivedFrom : "",
                              receiverName: formObj.receiverName ? formObj.receiverName : "",
                              simNumber: formObj.simNumber ? formObj.simNumber : "",
                              statusFlag: "A",
                              tabApkVersion: formObj.tabApkVersion ? formObj.tabApkVersion : "",
                              deviceSerialNo: formObj.equipmentSerialNo
                                  ? formObj.equipmentSerialNo
                                  : "",
                              hubIncharge: this.userCode,
                              completed: true,
                          };
                  } else {


                      jobCardDetails =
                          {
                              aasetSticket: formObj.assetSticker ? formObj.assetSticker : "",
                              actionTake: actionTake
                                  ? actionTake.toString()
                                  : "",
                              //actionTake: this.actionTakeList.map((x) => x.id).join(", "),
                              assetNo: formObj.assetNo ? formObj.assetNo : "",
                              busTicket: formObj.busTicket == "1" ? "Y" : "N",
                              centerName: formObj.centerName ? formObj.centerName : "",
                              chillingCenter: formObj.chillingCenter ? formObj.chillingCenter : "",
                              completed:
                                  formObj.ticketStatusCode.statusName == "Resolved" ? true : false,
                              dataSend: formObj.dataSend
                                  ? formObj.dataSend == "1"
                                      ? "Y"
                                      : "N"
                                  : "",
                              equipmentModel: formObj.equipmentModel ? formObj.equipmentModel : "",
                              equipmentName: formObj.equipmentName ? formObj.equipmentName : "",
                              equipmentSerialNo: formObj.equipmentSerialNo
                                  ? formObj.equipmentSerialNo
                                  : "",
                              feedApk: formObj.feedApk ? (formObj.feedApk == "1" ? "Y" : "N") : "",
                              hmbCode: formObj.hmbCode ? formObj.hmbCode : "",
                              hologramStickerAvailable: formObj.hologramStickerAvailable
                                  ? formObj.hologramStickerAvailable
                                  : "",
                              idJobCardNo: formObj.jobCardNo ? formObj.jobCardNo : "",
                              idTicketCode: "",
                              issuedTo: formObj.issuedTo ? formObj.issuedTo : "",
                              jobAttended: formObj.jobAttended ? formObj.jobAttended : 0,
                              jobCompleted: formObj.jobCompleted ? formObj.jobCompleted : 0,
                              mobileNumber: formObj.mobileNumber ? formObj.mobileNumber : "",
                              natureOfComplaint: natureOfComplaintData
                                  ? natureOfComplaintData.toString()
                                  : "",
                              plantCode: this.ticketData.plantCode ? this.ticketData.plantCode : "",
                              rdeCallAttend: formObj.rdeCallAttend
                                  ? formObj.rdeCallAttend == "1"
                                      ? "Y"
                                      : "N"
                                  : "",
                              receivedFrom: formObj.receivedFrom ? formObj.receivedFrom : "",
                              receiverName: formObj.receiverName ? formObj.receiverName : "",
                              simNumber: formObj.simNumber ? formObj.simNumber : "",
                              statusFlag: "A",
                              tabApkVersion: formObj.tabApkVersion ? formObj.tabApkVersion : "",
                              deviceSerialNo: formObj.equipmentSerialNo
                                  ? formObj.equipmentSerialNo
                                  : "",
                              hubIncharge: this.userCode,

                          };

                  }
                  let masters =
                      {
                          cancelRemarks: "",
                          hapHubCode: "",
                          hapHubRemarks: "",
                          hapLat: this.position ? this.position.lat : "",
                          hapLong: this.position ? this.position.lng : "",
                          ticketCode: this.ticketCode,
                          ticketStatusCode:
                              this.ticketData.ticketCurrentStatus == "HUB - Job Card Pending"
                                  ? 4
                                  : formObj.ticketStatusCode.statusCode, // formObj.ticketStatusCode.ticketStatusCode, // HUB,
                          ticketSubStatusCode:
                              this.ticketData.ticketCurrentStatus == "HUB - Job Card Pending"
                                  ? 17
                                  : formObj.ticketStatusCode.subStatusCode, // formObj.ticketStatusCode.ticketStatusCode, //HUB - In Progress,
                      };


                  let reqCalibration;

                  reqCalibration =
                          {
                              ticketCode:this.ticketCode,
                              requestRemarks: formObj.calibrationRemarks,
                              approveStatus : 'Y',
                              calibarationType : 'Calibration',
                          };

console.log("this.complaintInActiveList",this.complaintInActiveList)
                  let complaintlistdata;
                  if(this.complaintInActiveList.length === 0){
                      complaintlistdata =this.complaintList;
                  }else{
                      complaintlistdata = this.complaintInActiveList;
                  }
                  let formData = new FormData();
                  formData.append("aLineItems", "");
                  formData.append("bAttachment", "");
                  formData.append("aAttachment", "");
                  formData.append("calibarationHa", JSON.stringify(reqCalibration));
                  formData.append("compliantNatureData", JSON.stringify(complaintlistdata));
                  formData.append("jobData", JSON.stringify(jobCardDetails));
                  formData.append("masterData", JSON.stringify(masters));
                  formData.append("wLineItems", "");
                 /!* var reqCal = {
                      ticketCode: this.ticketCode,
                      compliantNatureData: this.complaintInActiveList,
                      aLineItems: [],
                      bAttachment: [],
                      aAttachment: [],
                      jobCard: jobCardDetails,
                      wLineItems: [],
                      master: masters,
                      calibarationHa: reqCalibration,

                  };*!/


                  try {
                      // this.isSubmitted = true;
                      let response = await this.ticketService.reqcalibrationJobcard(formData);

                      if (response ?.body ?.success)
                      {
                          this.isSubmitted = false;

                          this.sharedService.showNotification(
                              "snackbar-success",
                              response ?.body ?.message,
                          "bottom",
                          "center"
                      )
                          ;

                          await this.spinner.hide();
                          this.dialogRef.close({action: "jobCard"});
                      }
                  else
                      {
                          this.isSubmitted = false;
                          /!** spinner hide *!/
                          this.spinner.hide();
                          this.sharedService.showNotification(
                              "snackbar-danger",
                              response ?.body ?.message,
                          "bottom",
                          "center"
                      )
                          ;
                      }
                  } catch (e) {
                      this.isSubmitted = false;
                      this.dialogRef.close({action: "close"});
                  }
              } else {
                  this.isSubmitted = false;
                  this.sharedService.showNotification(
                      "snackbar-danger",
                      "This app needs the Location service, please turn on the Location",
                      "bottom",
                      "center"
                  );
              }
          } */
      this.isSubmitted = true;
      await this.getCurrentLocation();
      if (this.position) {
        let natureOfComplaintData = this.complaintList.map(
          (a) => a.complaintName
        );
        console.log("UPDATE NATUREOFCOMPLAINT", this.complaintList);
        console.log("UPDATE NATUREOFCOMPLAINT", natureOfComplaintData);

        let actionTake;
        let actionTakenList = formObj;
        if (
          actionTakenList.actionTake === undefined ||
          actionTakenList.actionTake === "" ||
          actionTakenList.actionTake === null
        ) {
          actionTake = "";
        } else {
          let actionTakeCheck = actionTakenList.actionTake.map(
            (a) => a.reasonName
          );
          if (
            actionTakeCheck[0] === undefined ||
            actionTakeCheck[0] === "" ||
            actionTakeCheck[0] === null
          ) {
            actionTake = actionTakenList.actionTake.map((a) => a);
          } else {
            actionTake = actionTakenList.actionTake.map((a) => a.reasonName);
          }
        }
        console.log("Action Taken", actionTake);
        console.log("whole data Taken", formObj);

        let jobCardDetails;
        console.log(
          "formObj.statusCode",
          formObj.ticketStatusCode.ticketStatusCode
        );
        console.log("formObj.statusCode", formObj.ticketStatusCode.statusCode);
        if (
          (formObj.ticketStatusCode.statusCode === 11 &&
            formObj.ticketStatusCode.subStatusCode === 11) ||
          (formObj.ticketStatusCode.statusCode === 15 &&
            formObj.ticketStatusCode.subStatusCode === 34)
        ) {
          console.log("status true");
          jobCardDetails = [
            {
              aasetSticket: formObj.assetSticker ? formObj.assetSticker : "",
              actionTake: actionTake ? actionTake.toString() : "",
              //actionTake: this.actionTakeList.map((x) => x.id).join(", "),
              assetNo: formObj.assetNo ? formObj.assetNo : "",
              busTicket: formObj.busTicket == "1" ? "Y" : "N",
              centerName: formObj.centerName ? formObj.centerName : "",
              chillingCenter: formObj.chillingCenter
                ? formObj.chillingCenter
                : "",

              dataSend: formObj.dataSend
                ? formObj.dataSend == "1"
                  ? "Y"
                  : "N"
                : "",
              equipmentModel: formObj.equipmentModel
                ? formObj.equipmentModel
                : "",
              equipmentName: formObj.equipmentName ? formObj.equipmentName : "",
              equipmentSerialNo: formObj.equipmentSerialNo
                ? formObj.equipmentSerialNo
                : "",
              feedApk: formObj.feedApk
                ? formObj.feedApk == "1"
                  ? "Y"
                  : "N"
                : "",
              hmbCode: formObj.hmbCode ? formObj.hmbCode : "",
              hologramStickerAvailable: formObj.hologramStickerAvailable
                ? formObj.hologramStickerAvailable
                : "",
              idJobCardNo: formObj.jobCardNo ? formObj.jobCardNo : "",
              idTicketCode: "",
              issuedTo: formObj.issuedTo ? formObj.issuedTo : "",
              jobAttended: formObj.jobAttended ? formObj.jobAttended : 0,
              jobCompleted: formObj.jobCompleted ? formObj.jobCompleted : 0,
              mobileNumber: formObj.mobileNumber ? formObj.mobileNumber : "",
              natureOfComplaint: natureOfComplaintData
                ? natureOfComplaintData.toString()
                : "",
              plantCode: this.ticketData.plantCode
                ? this.ticketData.plantCode
                : "",
              rdeCallAttend: formObj.rdeCallAttend
                ? formObj.rdeCallAttend == "1"
                  ? "Y"
                  : "N"
                : "",
              receivedFrom: formObj.receivedFrom ? formObj.receivedFrom : "",
              receiverName: formObj.receiverName ? formObj.receiverName : "",
              simNumber: formObj.simNumber ? formObj.simNumber : "",
              statusFlag: "A",
              tabApkVersion: formObj.tabApkVersion ? formObj.tabApkVersion : "",
              deviceSerialNo: formObj.equipmentSerialNo
                ? formObj.equipmentSerialNo
                : "",
              hubIncharge: this.userCode,
              completed: true,
            },
          ];
        } else {
          jobCardDetails = [
            {
              aasetSticket: formObj.assetSticker ? formObj.assetSticker : "",
              actionTake: actionTake ? actionTake.toString() : "",
              //actionTake: this.actionTakeList.map((x) => x.id).join(", "),
              assetNo: formObj.assetNo ? formObj.assetNo : "",
              busTicket: formObj.busTicket == "1" ? "Y" : "N",
              centerName: formObj.centerName ? formObj.centerName : "",
              chillingCenter: formObj.chillingCenter
                ? formObj.chillingCenter
                : "",
              completed:
                formObj.ticketStatusCode.statusName == "Resolved"
                  ? true
                  : false,
              dataSend: formObj.dataSend
                ? formObj.dataSend == "1"
                  ? "Y"
                  : "N"
                : "",
              equipmentModel: formObj.equipmentModel
                ? formObj.equipmentModel
                : "",
              equipmentName: formObj.equipmentName ? formObj.equipmentName : "",
              equipmentSerialNo: formObj.equipmentSerialNo
                ? formObj.equipmentSerialNo
                : "",
              feedApk: formObj.feedApk
                ? formObj.feedApk == "1"
                  ? "Y"
                  : "N"
                : "",
              hmbCode: formObj.hmbCode ? formObj.hmbCode : "",
              hologramStickerAvailable: formObj.hologramStickerAvailable
                ? formObj.hologramStickerAvailable
                : "",
              idJobCardNo: formObj.jobCardNo ? formObj.jobCardNo : "",
              idTicketCode: "",
              issuedTo: formObj.issuedTo ? formObj.issuedTo : "",
              jobAttended: formObj.jobAttended ? formObj.jobAttended : 0,
              jobCompleted: formObj.jobCompleted ? formObj.jobCompleted : 0,
              mobileNumber: formObj.mobileNumber ? formObj.mobileNumber : "",
              natureOfComplaint: natureOfComplaintData
                ? natureOfComplaintData.toString()
                : "",
              plantCode: this.ticketData.plantCode
                ? this.ticketData.plantCode
                : "",
              rdeCallAttend: formObj.rdeCallAttend
                ? formObj.rdeCallAttend == "1"
                  ? "Y"
                  : "N"
                : "",
              receivedFrom: formObj.receivedFrom ? formObj.receivedFrom : "",
              receiverName: formObj.receiverName ? formObj.receiverName : "",
              simNumber: formObj.simNumber ? formObj.simNumber : "",
              statusFlag: "A",
              tabApkVersion: formObj.tabApkVersion ? formObj.tabApkVersion : "",
              deviceSerialNo: formObj.equipmentSerialNo
                ? formObj.equipmentSerialNo
                : "",
              hubIncharge: this.userCode,
            },
          ];
        }
        let resolvedComments ;
        //resolved comments and scrap remarks
          if (formObj.ticketStatusCode.statusCode === 7 && formObj.ticketStatusCode.subStatusCode === 7) {
              resolvedComments = formObj.comments;
          }else if(formObj.ticketStatusCode.statusCode === 17 && formObj.ticketStatusCode.subStatusCode === 26){
              resolvedComments = formObj.scrapRequestRemarks;
          }
        let masters = [
          {
            hapRemarks: resolvedComments
              ? resolvedComments
              : "",
            hapGeoAddress: "",
            cancelRemarks: "",
            hapHubCode: "",
            hapHubRemarks: "",
            hapLat: this.position ? this.position.lat : "",
            hapLong: this.position ? this.position.lng : "",
            ticketCode: "",
            ticketStatusCode:
              this.ticketData.ticketCurrentStatus == "HUB - Job Card Pending"
                ? 4
                : formObj.ticketStatusCode.statusCode, // formObj.ticketStatusCode.ticketStatusCode, // HUB,
            ticketSubStatusCode:
              this.ticketData.ticketCurrentStatus == "HUB - Job Card Pending"
                ? 17
                : formObj.ticketStatusCode.subStatusCode, // formObj.ticketStatusCode.ticketStatusCode, //HUB - In Progress,
          },
        ];

        let assetScrap;
        if (formObj.scrapRequestRemarks === null) {
          assetScrap = [];
        } else {
          assetScrap = [
            {
              hapHubCode: this.hubCode ? this.hubCode : "",
              approvedRemarks: "",
              approvedStatus: "",
              assetAgeInDays: "",
              assetAgeInWords: "",
              assetCode: this.ticketData.hapAssetCode
                ? this.ticketData.hapAssetCode
                : "",
              deptCode: this.ticketData.hapDeptCode
                ? this.ticketData.hapDeptCode
                : "",
              recommendRemarks: "",
              recommendStatus: "",
              scrapId: "",
              scrapRequestRemarks: formObj.scrapRequestRemarks
                ? formObj.scrapRequestRemarks
                : "",
              ticketCode: "",
              verifiedRemarks: "",
              verifiedStatus: "",
            },
          ];
        }
        let complaintlistdata;
        if (this.complaintInActiveList.length === 0) {
          complaintlistdata = this.complaintList;
        } else {
          complaintlistdata = this.complaintInActiveList;
        }
        var ticketEdit = {
          ticketCode: this.ticketCode,
          assetCompliants: complaintlistdata,
          assetIntake: [],
          assetMaterials: [],
          assetTravel: [],
          jobCard: jobCardDetails,
          comments: [],
          master: masters,
          users: [],
          assetScrap: assetScrap,
        };

        try {
          // this.isSubmitted = true;
          let response = await this.ticketService.assetIntakeTicket(ticketEdit);

          if (response?.body?.success) {
            this.isSubmitted = false;

            this.sharedService.showNotification(
              "snackbar-success",
              response?.body?.message,
              "bottom",
              "center"
            );

            await this.spinner.hide();
            this.dialogRef.close({ action: "jobCard" });
          } else {
            this.isSubmitted = false;
            /** spinner hide */
            this.spinner.hide();
            this.sharedService.showNotification(
              "snackbar-danger",
              response?.body?.message,
              "bottom",
              "center"
            );
          }
        } catch (e) {
          this.isSubmitted = false;
          this.dialogRef.close({ action: "close" });
        }
      } else {
        this.isSubmitted = false;
        this.sharedService.showNotification(
          "snackbar-danger",
          "This app needs the Location service, please turn on the Location",
          "bottom",
          "center"
        );
      }
      //  }
    }
  }
  numberValidator(control: FormControl) {
    if (isNaN(control?.value)) {
      return {
        number: true,
      };
    }
    return null;
  }

  async getUOMList() {
    const response = await this.ticketService.getUOMList();

    this.uomList = response.data || [];
  }

  async hubEmployeeList() {
    let allUser = false;
    const response = await this.ticketService.getMappingEmployee(allUser);

    this.employeeList = response.data || [];
  }

  //Material List
  async ticketAssetMaterial() {
    let ticketCode = this.ticketCode;
    let resObj = { ticketCode };
    const response = await this.ticketService.getMaterialList(resObj);
    this.assetMaterialList = response.body.data || [];
  }

  compareFn(d1, d2): boolean {
    if (d1.subStatusCode) {
      return d1 && d2 ? d1.subStatusCode === d2.ticketSubStatusCode : d1 === d2;
    }
  }
  async getHAPStatus(projectCode, moduleCode) {
    let reqObj = { projectCode, moduleCode };
    const response = await this.ticketService.getHAPStatus(reqObj);
    this.statusList = response.data || [];
  }

  async getComplaintByAsset(assetCode, deptCode) {
    let assetType = this.hapAssetType;
    let projectCode = this.ticketData.projectCode;
    let reqObj = { assetCode, deptCode, assetType ,projectCode };
    const response = await this.ticketService.getComplaintAssetData(reqObj);
    this.eqpModel = response.data[0].catName;
    this.natureOfComplaintList = response.data || [];
    this.consumableJobCardForm.patchValue({
      equipmentModel: response.data.length > 0 ? response.data[0].model : "",
    });
    this.mobTabValidation();
  }
  async getEquipment(assetCode, deptCode) {
    let assetType = this.hapAssetType;
    let reqObj = { assetCode, deptCode, assetType };
    const response =
      await this.ticketService.getHapAssetDetailsByAssetCodeAndDept(reqObj);
    this.equipNumberList = response.data[0] || [];
    this.consumableJobCardForm.patchValue({
      equipmentName:
        response.data.length > 0 ? response.data[0].assetCatName : "",
      equipNo: response.data.length > 0 ? this.equipNumberList.equipNo : "",
      equipmentSerialNo:
        response.data.length > 0 ? this.equipNumberList.deviceSerialNo : "",
    });
  }
  mobTabValidation() {
    if (this.eqpModel == "MOBILETAB") {
      this.mobTabHide = true;
    } else {
      this.mobTabHide = false;
    }
  }
  async getTicketAssetComplaints() {
    let reqObj = { ticketCode: this.ticketCode };
    const response = await this.ticketService.getTicketAssetComplaints(reqObj);

    this.consumableJobCardForm.patchValue({
      natureOfComplaint: response.data,
    });
    console.log("patch complaint", response.data);
    this.deSelectList = response.data;

    this.complaintList = response.data.map((item) => {
      return {
        catId: item.idCatId,
        companyCode: item.idCompanyCode,
        compliantId: item.idCompliantId,
        complaintName: item.compliantName,
        deptCode: item.idDeptCode,
        hapAssetCode: item.idHapAssetCode,
        statusFlag: item.statusFlag,
        subCatId: item.idSubCatId,
        ticketCode: "",
        estMins: item.estMins ? item.estMins : 0,
        graceMins: item.graceMins ? item.graceMins : 0,
      };
    });
    this.complainInActive = response.data.map((item) => {
      return {
        catId: item.idCatId,
        companyCode: item.idCompanyCode,
        compliantId: item.idCompliantId,
        complaintName: item.compliantName,
        deptCode: item.idDeptCode,
        hapAssetCode: item.idHapAssetCode,
        statusFlag: "X",
        subCatId: item.idSubCatId,
        ticketCode: "",
        estMins: item.estMins ? item.estMins : 0,
        graceMins: item.graceMins ? item.graceMins : 0,
      };
    });
  }

  onActionSelect(items: any) {
    let filterData = this.reasonList.filter(
      (obj) => obj.reasonName == items.reasonName
    );
    console.log("reasonName", filterData);
    let actionTakeList = [];
    let data = {
      reasonName: filterData[0].reasonName,
    };
    actionTakeList.push(data);
    this.actionTakeList = actionTakeList.filter(function (element) {
      return element !== undefined;
    });

    console.log("this.actionTakeList", this.actionTakeList);

    if (
      this.actionTakeList.length === 0 &&
      (this.ticketStatus === "Scrap" ||
        this.ticketStatus === "Device Installation")
    ) {
      this.statusDisable = true;
    } else {
      this.statusDisable = false;
    }
    if (
      ((this.resolveStatus === 11 && this.resolveSubStatus === 11) ||
        (this.resolveStatus === 4 && this.resolveSubStatus === 29) ||
        (this.resolveStatus === 7 && this.resolveSubStatus === 7) ||
        (this.resolveStatus === 17 && this.resolveSubStatus === 26)) &&
      this.actionTakeList.length === 0
    ) {
      this.actionTakenVal = true;
    } else {
      this.actionTakenVal = false;
    }

    this.getTicketHistory(this.ticketCode);
  }

  public onActionDeSelect(item: any) {
    console.log("deselect actionTakeList", this.actionTakeList);
    console.log("deselect actionTakeList", item);
    const Temp = this.actionTakeList.filter(
      (obj) => obj.reasonName != item.reasonName
    );
    this.actionTakeList = Temp;
    console.log("deselect option", this.actionTakeList);

    let deselectObj = this.consumableJobCardForm.getRawValue();
    let ticketStatusCode =
      this.consumableJobCardForm.get("ticketStatusCode").value;
    //Action Taken * for deviceinstallation,partreplacement,resolved,scrap
    if (
      ((this.resolveStatus === 11 && this.resolveSubStatus === 11) ||
        (this.resolveStatus === 4 && this.resolveSubStatus === 29) ||
        (this.resolveStatus === 7 && this.resolveSubStatus === 7) ||
        (this.resolveStatus === 17 && this.resolveSubStatus === 26) ||
        (this.resolveStatus === 15 && this.resolveSubStatus === 34)) &&
      deselectObj.actionTake.length === 0
    ) {
      this.actionTakenVal = true;
    } else {
      this.actionTakenVal = false;
    }
  }

  onComplaintSelect(items: any) {
    let filterData = this.natureOfComplaintList.filter(
      (obj) => obj.compliantName == items.compliantName
    );

    let data = {
      catId: filterData[0].idCatId,
      companyCode: filterData[0].idCompanyCode,
      compliantId: filterData[0].idCompliantId,
      complaintName: filterData[0].compliantName,
      deptCode: filterData[0].idDeptCode,
      hapAssetCode: this.consumableJobCardForm.get("assetNo").value
        ? this.consumableJobCardForm.get("assetNo").value
        : "",
      statusFlag: filterData[0].statusFlag,
      subCatId: filterData[0].idSubCatId,
      ticketCode: "",
    };

    this.complaintList.push(data);
    this.complaintInActiveList.push(data);
  }
  public onComplaintDeSelect(item: any) {
    console.log(".........", item);
    let inActiveFlag;
    let filterDataList = this.deSelectList.filter(
      (obj) => obj.createdBy !== this.userCode
    );
    let filterDataNow = filterDataList.filter(
      (obj) => obj.compliantName == item.compliantName
    );
    if (filterDataNow.length === 0) {
      //Inactive Status Flag
      this.complaintInActiveList = this.complainInActive.map((obj) => {
        let statusFlag;
        if (obj.complaintName == item.compliantName) {
          statusFlag = "X";
          console.log(".........suzzzzzz X", obj);
        } else {
          statusFlag = "A";
        }
        console.log("statusFlag....suzzzzzz", statusFlag);
        return {
          catId: obj.catId,
          companyCode: obj.companyCode,
          compliantId: obj.compliantId,
          complaintName: obj.complaintName,
          deptCode: obj.deptCode,
          hapAssetCode: obj.hapAssetCode,
          statusFlag: statusFlag,
          subCatId: obj.subCatId,
          ticketCode: "",
          estMins: obj.estMins ? obj.estMins : 0,
          graceMins: obj.graceMins ? obj.graceMins : 0,
        };
      });

      //this.complaintInActiveList.push(complaintInActiveList);
      console.log("Complaint Listtttt", this.complaintInActiveList);
      //deselect function
      const Temp = this.complaintList.filter(
        (obj) => obj.complaintName !== item.compliantName
      );
      this.complaintList = Temp;
    } else {
      if (filterDataNow[0].compliantName == item.compliantName) {
        this.consumableJobCardForm.patchValue({
          natureOfComplaint: this.deSelectList,
        });
        this.complaintList = this.deSelectList;
      } else {
        //Inactive Status Flag
        this.complaintInActiveList = this.complainInActive.map((obj) => {
          let statusFlags;
          if (obj.complaintName == item.compliantName) {
            console.log(".........>>>", obj);
            statusFlags = "X";
          } else {
            statusFlags = "A";
          }

          return {
            catId: obj.catId,
            companyCode: obj.companyCode,
            compliantId: obj.compliantId,
            complaintName: obj.complaintName,
            deptCode: obj.deptCode,
            hapAssetCode: obj.hapAssetCode,
            statusFlag: statusFlags,
            subCatId: obj.subCatId,
            ticketCode: "",
            estMins: obj.estMins ? obj.estMins : 0,
            graceMins: obj.graceMins ? obj.graceMins : 0,
          };
        });
        //  this.complaintInActiveList.push(complaintInActiveList);
        const Temp = this.complaintList.filter(
          (obj) => obj.complaintName !== item.compliantName
        );
        this.complaintList = Temp;
      }
    }
  }

  verifyStatus() {
    let formData = this.consumableJobCardForm.getRawValue();

    /* if (formData.ticketStatusCode.statusName == "Resolved") {
            this.happyCode = "";
            this.createJob();
            this.isPopupVisible = false;
        } else {
            this.createJob();
        }*/

    this.createJob();
  }
  verifyHappyCode() {
    if (this.happyCode) {
      this.spinner.show();
      if (this.hapHappyCode === this.happyCode) {
        this.hapCodeValid = false;
        this.sharedService.showNotification(
          "snackbar-success",
          "Successfully",
          "bottom",
          "center"
        );
        setTimeout(() => {
          this.spinner.hide();
          this.isPopupVisible = false;
          this.createJob();
        }, 2000);
      } else {
        this.hapCodeValid = true;
        this.spinner.hide();
        // this.sharedService.showNotification(
        //   'snackbar-danger',
        //   'Please Enter Correct Hap Code',
        //   'bottom',
        //   'center',
        // );
      }
    } else {
      this.happyCode = "";
      // this.sharedService.showNotification(
      //   'snackbar-danger',
      //   'Please Enter Hap Code',
      //   'bottom',
      //   'center',
      // );
    }
  }

  //ticket History api used for validation
  async getTicketHistory(ticketCode) {
    let reqObj = { ticketCode };
    const response = await this.ticketService.getTicketHistory(reqObj);
    console.log("response?.body>>", response?.body?.data);
    this.ticketTimeLineData = response?.body?.data;
    let statusCode = this.ticketTimeLineData.map((a) => a.statusCode);
    let subStatusCode = this.ticketTimeLineData.map((a) => a.subStatusCode);
    console.log("STATUS HISTORY", statusCode);
    console.log("SUBSTATUS HISTORY", subStatusCode);
    //Calibration,DeviceInstallation Validation UPDATE button Disable
    /*  device replacement this.statusHistory = statusCode.includes(21);
        this.subStatusHistory = subStatusCode.includes(33);*/
    this.reqCalibrationStatusHistory = statusCode.includes(15);
    this.reqCalibrationsubStatusHistory = subStatusCode.includes(34);
    this.calInprogressStatusHistory = statusCode.includes(6);
    this.calInprogresssubStatusHistory = subStatusCode.includes(35);
    this.calCompletedStatusHistory = statusCode.includes(6);
    this.calCompletedSubStatusHistory = subStatusCode.includes(36);
    this.deviceInstallStatusHistory = statusCode.includes(11);
    this.deviceInstallSubStatusHistory = subStatusCode.includes(11);
    if (
      (this.ticketData.ticketStatusCode === 15 &&
        this.ticketData.ticketSubStatusCode === 34) ||
      (this.ticketData.ticketStatusCode === 6 &&
        this.ticketData.ticketSubStatusCode === 35) ||
      (this.ticketData.ticketStatusCode === 6 &&
        this.ticketData.ticketSubStatusCode === 36) ||
      (this.ticketData.ticketStatusCode === 10 &&
        this.ticketData.ticketSubStatusCode === 10) ||
      (this.ticketData.ticketStatusCode === 11 &&
        this.ticketData.ticketSubStatusCode === 11)
    ) {
      this.statusDisable = true;
    } else {
      this.statusDisable = false;
    }
  }

  async getTicketHistoryPartReplacement() {
    let statusCode = this.ticketTimeLineData.map((a) => a.statusCode);
    let subStatusCode = this.ticketTimeLineData.map((a) => a.subStatusCode);
    console.log("STATUS HISTORY", statusCode);
    console.log("SUBSTATUS HISTORY", subStatusCode);
    //Once PartReplacement Done without material should not allow to change status
    this.partReplacementStatusHistory = statusCode.includes(4);
    this.partReplacementSubStatusHistory = subStatusCode.includes(29);
    if (
      this.partReplacementStatusHistory === true &&
      this.partReplacementSubStatusHistory === true &&
      this.assetMaterialList.length === 0
    ) {
      Swal.fire({
        title: "Information",
        text: "Unable to change the status without materials",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.consumableJobCardForm.get("ticketStatusCode").setValue({
            ticketStatusCode: this.ticketData.ticketStatusCode,
            ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
            ticketStatusName: this.ticketData.statusName,
          });
        } else {
          this.consumableJobCardForm.get("ticketStatusCode").setValue({
            ticketStatusCode: this.ticketData.ticketStatusCode,
            ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
            ticketStatusName: this.ticketData.statusName,
          });
        }
      });
    } else {
    }
    //Once Device Replacement Done Only Resolve done other status should not allow to edit
    this.deviceReplacementStatusHistory = statusCode.includes(21);
    this.deviceReplacementSubStatusHistory = subStatusCode.includes(33);
  }

  getresolvedVal() {
    //Once Device Replacement Done Only Resolve done other status should not allow to edit
    if (
      this.deviceReplacementStatusHistory === true &&
      this.deviceReplacementSubStatusHistory === true &&
      this.resolveStatus === 7 &&
      this.resolveSubStatus === 7
    ) {
      console.log("onchange status resolve>>>>>>", this.resolveStatus);
      this.consumableJobCardForm.get("issuedTo").enable();
      this.consumableJobCardForm.get("mobileNumber").enable();
    } else {
      console.log("onchange status>>>>>>");
      Swal.fire({
        title: "Information",
        text: "Unable to change the status.It's already DeviceReplaced done",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.consumableJobCardForm.get("ticketStatusCode").setValue({
            ticketStatusCode: this.ticketData.ticketStatusCode,
            ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
            ticketStatusName: this.ticketData.statusName,
          });
          this.consumableJobCardForm.get("issuedTo").disable();
          this.consumableJobCardForm.get("mobileNumber").disable();
        } else {
          this.consumableJobCardForm.get("ticketStatusCode").setValue({
            ticketStatusCode: this.ticketData.ticketStatusCode,
            ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
            ticketStatusName: this.ticketData.statusName,
          });
          this.consumableJobCardForm.get("issuedTo").disable();
          this.consumableJobCardForm.get("mobileNumber").disable();
        }
      });
    }
  }
  async onChangeBasicDetails(key, event) {
      this.consumableJobCardForm.get("issuedTo").setValue(null);

      let statusCode = event.value.statusCode;
    let subStatusCode = event.value.subStatusCode;
    this.resolveStatus = event.value.statusCode;
    this.resolveSubStatus = event.value.subStatusCode;
    console.log("can  change status", statusCode);
    console.log("can  change substatus", subStatusCode);


    this.getTicketHistoryPartReplacement();
    if (
      (this.statusHistory === true &&
        statusCode === 17 &&
        this.subStatusHistory === true &&
        subStatusCode === 26) ||
      (this.statusHistory === true &&
        statusCode === 4 &&
        this.subStatusHistory === true &&
        subStatusCode === 29)
    ) {
      console.log("onchange status");
      Swal.fire({
        title: "Information",
        text: "Unable to change the status.It's already DeviceReplaced done",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.consumableJobCardForm.get("ticketStatusCode").setValue({
            ticketStatusCode: this.ticketData.ticketStatusCode,
            ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
            ticketStatusName: this.ticketData.statusName,
          });
        } else {
          this.consumableJobCardForm.get("ticketStatusCode").setValue({
            ticketStatusCode: this.ticketData.ticketStatusCode,
            ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
            ticketStatusName: this.ticketData.statusName,
          });
        }
      });
    } else {
    }
    let actionTakenValue = this.consumableJobCardForm.get("actionTake").value;
    if (statusCode === 17 && subStatusCode === 26) {
      this.scrapRecommend = true;
      this.consumableJobCardForm
        .get("scrapRequestRemarks")
        .setValidators([Validators.required]);
      this.consumableJobCardForm
        .get("scrapRequestRemarks")
        .updateValueAndValidity();
    } else {
      this.scrapRecommend = false;
      this.consumableJobCardForm.get("scrapRequestRemarks").setValue(null);
      this.consumableJobCardForm.get("scrapRequestRemarks").clearValidators();
      this.consumableJobCardForm
        .get("scrapRequestRemarks")
        .updateValueAndValidity();
    }
    //req for calibaration
    //Action Taken * for deviceinstallation,partreplacement,resolved,scrap,req for calibration
    if (
      ((statusCode === 11 && subStatusCode === 11) ||
        (statusCode === 4 && subStatusCode === 29) ||
        (statusCode === 7 && subStatusCode === 7) ||
        (statusCode === 17 && subStatusCode === 26) ||
        (statusCode === 15 && subStatusCode === 34)) &&
      actionTakenValue.length === 0
    ) {
      this.actionTakenVal = true;
    } else {
      this.actionTakenVal = false;
    }
    //resolved comment should enable
      console.log("jconsumable job card>> calibration",statusCode)
      console.log("jconsumable job card>>", this.consumableJobCardForm.get("comments"))
      if((statusCode === 7 && subStatusCode === 7)){
          this.consumableJobCardForm.get("comments").enable();
      }else{
          this.consumableJobCardForm.get("comments").disable();
          this.consumableJobCardForm.get("comments").setValue("");
      }


      //mobile and issuse enable for calibration and device installation, resolved

      if((statusCode === 7 && subStatusCode === 7) || (statusCode === 11 && subStatusCode === 11) ||
          (statusCode ===15 && subStatusCode === 34)){
          debugger;
      this.consumableJobCardForm.get("issuedTo").enable();
      this.consumableJobCardForm.get("mobileNumber").enable();
      this.resolveVal = true;
          this.consumableJobCardForm
              .get("mobileNumber")
              .setValidators( [Validators.pattern("[6-9]\\d{9}")]);

    } else {
      this.consumableJobCardForm.get("issuedTo").disable();
      this.consumableJobCardForm.get("mobileNumber").disable();
      this.consumableJobCardForm.get("issuedTo").setValue(null);
      this.consumableJobCardForm.get("issuedTo").clearValidators();
      this.consumableJobCardForm.get("mobileNumber").setValue(null);
      this.consumableJobCardForm.get("mobileNumber").clearValidators();
      this.resolveVal = false;
    }
    if (key == "status") {
      let ticketStatus;
      if (event.value.ticketCurrentStatus.indexOf("-") > 0) {
        let split = event.value.ticketCurrentStatus.split("-");
        ticketStatus = split[0].trim();
      } else {
        ticketStatus = event.value.ticketCurrentStatus;
      }
      //On Change calibrationFlag Check and Flow
      let calibarationFlag = this.ticketData.calibarationFlag;
      console.log("calibrationnn", calibarationFlag);
      console.log("hapassetName cal", this.ticketData.hapAssetName);
      if (statusCode === 15 && subStatusCode === 34 && calibarationFlag === 0) {
        this.reqCalRecommend = false;
        Swal.fire({
          title: "Information",
          text: "Asset is not applicable for Calibration",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.consumableJobCardForm.get("ticketStatusCode").setValue({
              ticketStatusCode: this.ticketData.ticketStatusCode,
              ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
              ticketStatusName: this.ticketData.statusName,
            });
          } else {
            this.consumableJobCardForm.get("ticketStatusCode").setValue({
              ticketStatusCode: this.ticketData.ticketStatusCode,
              ticketSubStatusCode: this.ticketData.ticketSubStatusCode,
              ticketStatusName: this.ticketData.statusName,
            });
          }
        });
      }
      if (statusCode === 15 && subStatusCode === 34) {
        this.loadReqcalReason();
        this.reqCalRecommend = true;
      } else {
        this.reqCalRecommend = false;
      }
    }

    if (
      this.deviceReplacementStatusHistory === true &&
      this.deviceReplacementSubStatusHistory === true
    ) {
      this.getresolvedVal();
    } else {
    }
      let issuedToValue = this.consumableJobCardForm.get("issuedTo").value;
    if(issuedToValue !== null){
        if(issuedToValue.length === 0){

        }else{
            this.resolveVal = false;
        }
    }

  }
  //onChange Issuse,Mobile
  onChangeResolve(e) {
    let resolveValue = e.target.value;
    let issuedTo = this.consumableJobCardForm.get("issuedTo").value;
 //   let mobileNumber = this.consumableJobCardForm.get("mobileNumber").value;
    if (
      issuedTo === "" ||
      issuedTo === null

    ) {
      this.resolveVal = true;
    } else {
      this.resolveVal = false;
    }
  }

  async getCurrentLocation() {
    try {
      this.position = await this.sharedService.getCurrentLocation();
    } catch (e) {
      this.position = null;
    }
  }
  onOtpChange(event) {
    this.hapCodeValid = false;
    this.happyCode = event;
    if (this.happyCode) {
      this.hapCodeRequired = false;
    } else {
      this.hapCodeRequired = true;
    }
  }

  filterDropDowns() {
    setTimeout(() => (this.searchTicketStatus = ""), 2000);
  }
  getControlLabel(type: string) {
    return this.consumableJobCardForm.controls[type].value;
  }
}
