import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, lastValueFrom, Subject } from "rxjs";
import { TicketModel } from "./ticket.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { UnsubscribeOnDestroyAdapter } from "../shared/UnsubscribeOnDestroyAdapter";
import { ApiService } from "../core/service/api.service";
import { reject, resolve } from "q";
import { takeUntil } from "rxjs/operators";
@Injectable()
export class TicketNewService {
  //  private readonly API_URL = 'assets/data/licensetype.json';
  isTblLoading = true;
  ticketBaseApi = "/api/nerve/ticket";

  dataChange: BehaviorSubject<TicketModel[]> = new BehaviorSubject<
    TicketModel[]
  >([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  dialogDataStatus: any;
  private detailsDestroy$ = new Subject<void>();
  constructor(private httpClient: HttpClient, public apiService: ApiService) {
    // super();
  }

  async updateTicket(ticketEdit) {
    this.dialogData = ticketEdit;
    const updateticket$ = this.apiService.post(
      `${this.ticketBaseApi}/editTicket`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(updateticket$);

    return response;
  }
  async assetIntakeTicket(ticketEdit) {
    //updateTicket
    this.dialogData = ticketEdit;
    const updateticket$ = this.apiService.post(
      `${this.ticketBaseApi}/v1/updateTicket`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(updateticket$);

    return response;
  }
  //Req for Calibration
  async reqcalibrationJobcard(formData: FormData) {
    this.dialogData = formData;
    const updateticket$ = this.apiService.post(
      `${this.ticketBaseApi}/v1/saveAndCalucateCalibrationDetails`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(updateticket$);

    return response;
  }
  async getScrapList(reqObj: any) {
    const scrapList$ = this.apiService.get(
      "/api/nerve/ticket/getHapTicketScrapByTicketCode",
      reqObj,
      true
    );
    let response = await lastValueFrom(scrapList$);
    return response.body;
  }

  async assetTravelExpense(ticketEdit) {
    //assetTravelExpenseAttachment
    this.dialogData = ticketEdit;
    const updateticket$ = this.apiService.post(
      `${this.ticketBaseApi}/v1/assetTravelExpenseAttachment`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(updateticket$);

    return response;
  }

  async getReason(searchCriteria) {
    let categoryId = searchCriteria.categoryId;

    const List$ = this.apiService.get(
      "/api/hap/master/getHapReasonList",
      { categoryId },
      false
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  async getReasonList(searchCriteria) {
    let categoryId = searchCriteria.categoryId;

    const List$ = this.apiService.get(
      "/api/hap/master/getHapReasonMaster",
      { categoryId },
      false
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  // get Project Service
  async getProject(body) {
    const projectList$ = this.apiService.post(
      `${this.ticketBaseApi}/getProjectList`,
      body,
      {},
      true
    );
    let response = await lastValueFrom(projectList$);
    return response.body;
  }

  // get User Service
  async getUser(body) {
    const userList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAgentList`,
      {},
      true
    );
    let response = await lastValueFrom(userList$);
    return response.body;
  }
  // get Assigned Service
  async getTicketassigned(reqObj: any) {
    const projectList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAgentDetails`,
      reqObj,
      true
    );
    let response = await lastValueFrom(projectList$);
    return response.body;
  }
  // save attachment
  async addAttachmentTicket(formData: FormData) {
    this.dialogData = formData;
    const addticketattach$ = this.apiService.post(
      `${this.ticketBaseApi}/saveOrUpdateTicketAttachment`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(addticketattach$);

    return response;
  }
  // save agent
  async addAgentTicket(ticketAssignmentRequest) {
    this.dialogData = ticketAssignmentRequest;
    const addticketagent$ = this.apiService.post(
      `${this.ticketBaseApi}/addTicketAgent`,
      ticketAssignmentRequest,
      "",
      true
    );
    let response = await lastValueFrom(addticketagent$);

    return response;
  }
  async addRetrieveHours(retrieveHoursRequest) {
    this.dialogData = retrieveHoursRequest;
    const retrieveHours$ = this.apiService.post(
      `${this.ticketBaseApi}/retrieveHours`,
      retrieveHoursRequest,
      "",
      true
    );
    let response = await lastValueFrom(retrieveHours$);

    return response;
  }
  // get Module Service
  async getModule(reqObj: any) {
    const moduleList$ = this.apiService.get(
      `${this.ticketBaseApi}/getModulesAndTicketType`,
      reqObj,
      true
    );
    let response = await lastValueFrom(moduleList$);
    return response.body;
  }

  //get Module

  async getAssignedUsers(ticketCode) {
    const assignedList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketHapTicketAssignedUsers`,
      { ticketCode },
      true
    );
    let response = await lastValueFrom(assignedList$);
    return response.body;
  }

  async getTicketModule(projets: any) {
    let projetsList = projets.projectCode;
    console.log("projetsList", projetsList);
    this.dialogData = projetsList.map((item) => {
      return { projectCode: item };
    });
    console.log("this.dialogData", this.dialogData);
    const moduleList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketModuleListForReport`,
      { project: this.dialogData },
      {},
      true
    );
    let response = await lastValueFrom(moduleList$);
    return response.body;
  }
  // ticket Type

  async getTicketType(projets: any) {
    let projetsList = projets.projectCode;
    console.log("projetsList", projetsList);
    this.dialogData = projetsList.map((item) => {
      return { projectCode: item };
    });
    console.log("this.dialogData", this.dialogData);
    const moduleList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketTypeListForReport`,
      { project: this.dialogData },
      {},
      true
    );
    let response = await lastValueFrom(moduleList$);
    return response.body;
  }
  // status
  async getStatusList(projets: any) {
    let projetsList = projets.projectCode;
    let ticketTypeList = projets.ticketType;
    console.log("projetsList", projets);
    this.dialogData = projetsList.map((item) => {
      return { projectCode: item };
    });
    this.dialogDataStatus = ticketTypeList.map((item) => {
      return { ticketTypeCode: item };
    });
    console.log("this.dialogData", this.dialogData);
    const statusList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketStatusListForReport`,
      { project: this.dialogData, ticketType: this.dialogDataStatus },
      {},
      true
    );
    let response = await lastValueFrom(statusList$);
    return response.body;
  }
  // Severity

  async getSeverity(projets: any) {
    let projetsList = projets.projectCode;
    let severityList = projets.ticketType;
    console.log("projetsList", projets);
    let list = projetsList.map((item) => {
      return { projectCode: item };
    });
    let severity = severityList.map((item) => {
      return { ticketTypeCode: item };
    });
    console.log("this.dialogData", this.dialogData);
    const severityList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketPriorityListForReport`,
      { project: list, ticketType: severity },
      {},
      true
    );
    let response = await lastValueFrom(severityList$);
    return response.body;
  }
  //Get Detailed Report
  async getDetailedDataService(getData) {
    // let page = searchCriteria.pageNo;
    // let size = searchCriteria.pageSize;
    // let sort = searchCriteria.sortColumn;
    // let sortByAscending=true;
    this.dialogData = getData;
    const detailedDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getSummaryAndDetailedReport`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(detailedDataList$);
    return response;
  }
  //Get TimeSheet Report
  async getTimeSheetDataService(getData) {
    this.dialogData = getData;
    const TimeSheetDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTimeSheetReport`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(TimeSheetDataList$);
    return response;
  }
  //Get booked history view
  async getbookedHrsViewService(getData) {
    this.dialogData = getData;
    const TimeSheetDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketHoursBooking`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(TimeSheetDataList$);
    return response;
  }
  //Get Leave Permission view
  async getLeavePermissionList(getleavePermission) {
    this.dialogData = getleavePermission;
    const TimeSheetDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getLmsPunchOrLeaveHistory`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(TimeSheetDataList$);
    return response;
  }
  //Get Project Wise Report
  async getProjectWiseService(searchCriteria, getData) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = true;
    this.dialogData = getData;
    const detailedDataList$ = this.apiService.post(
      `${this.ticketBaseApi}/getProjectWiseReport`,
      this.dialogData,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(detailedDataList$);
    return response;
  }
  // get Subgroup Service
  async getProjectSubgroup(reqObj: any) {
    const subgroupList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectSubGroups`,
      reqObj,
      true
    );
    let response = await lastValueFrom(subgroupList$);
    return response.body;
  }
  // get User Info
  async getUserdetail(reqObj: any) {
    const userList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAgentDetailsByUserCode`,
      reqObj,
      true
    );
    let response = await lastValueFrom(userList$);
    return response.body;
  }
  // get Agent Service
  async getProjectAgent(subGroupCode, ticketCode) {
    //  let subGroupCode = reqObj.subGroupCode;
    const subgroupList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAgentsBySubGroup`,
      { subGroupCode, ticketCode },
      true
    );
    let response = await lastValueFrom(subgroupList$);
    return response.body;
  }
  async getProjectAgentedit(subGroupCode) {
    const subgroupList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAgentsBySubGroup`,
      { subGroupCode },
      true
    );
    let response = await lastValueFrom(subgroupList$);
    return response.body;
  }
  // get Ticket Type Service
  /* async getTicketType(reqObj: any){
        const typeList$ = this.apiService.get('/api/ticket/create/getProjectList', reqObj, '' , true);
        let response = await lastValueFrom(typeList$);
        return response;
    } */
  // get Classification Service
  async getClassification(reqObj: any) {
    const classList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectClassifications`,
      reqObj,
      true
    );
    let response = await lastValueFrom(classList$);
    return response.body;
  }
  // get Priority Service
  async getPriority(reqObj: any) {
    console.log("reqobj", reqObj);
    const priorityList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectPriorities`,
      reqObj,
      true
    );
    let response = await lastValueFrom(priorityList$);
    return response.body;
  }

  async getReferenceList(reqObj: any) {
    console.log("reqobj", reqObj);
    const referenceList$ = this.apiService.get(
      `${this.ticketBaseApi}/getReferanceTickets`,
      reqObj,
      true
    );
    let response = await lastValueFrom(referenceList$);
    return response.body;
  }
  async getRetrieveTicketList(reqObj: any) {
    console.log("reqobj", reqObj);
    const retrieveList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTickets`,
      reqObj,
      true
    );
    let response = await lastValueFrom(retrieveList$);
    return response.body;
  }
  // get Status Service
  async getStatus(reqObj: any) {
    console.log("reqobj", reqObj);

    const statusList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectStatus`,
      reqObj,
      true
    );
    let response = await lastValueFrom(statusList$);
    return response.body;
  }

  //get HAP project status

  async getHAPStatus(reqObj: any) {
    console.log("reqobj", reqObj);

    const statusList$ = this.apiService.get(
      `${this.ticketBaseApi}/getHapProjectStatus`,
      reqObj,
      true
    );
    let response = await lastValueFrom(statusList$);
    return response.body;
  }
  // get ResponsibleAgent Service
  async getResAgentedit(reqObj: any) {
    const resAgentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProjectResponsibleAgents`,
      reqObj,
      true
    );
    let response = await lastValueFrom(resAgentList$);
    return response.body;
  }
  // get Version Service
  async getVersion(reqObj: any) {
    const versionList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketVersions`,
      reqObj,
      true
    );
    let response = await lastValueFrom(versionList$);
    return response.body;
  }

  // get Agent History Service
  async getAgentHistory(reqObj: any) {
    const agentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAgentUserLog`,
      reqObj,
      true
    );
    let response = await lastValueFrom(agentList$);
    return response.body;
  }
  // get Attachment Service
  async getAttachmentType() {
    const attachmentTypeList$ = this.apiService.get(
      `${this.ticketBaseApi}/getAttachmentTypes`,
      "",
      true
    );
    let response = await lastValueFrom(attachmentTypeList$);
    return response.body;
  }
  //Attachment List
  async getAttachmentList(reqObj: any) {
    const attachmentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAttachments`,
      reqObj,
      true
    );
    let response = await lastValueFrom(attachmentList$);
    return response;
  }
  //Total Billable & Non Billable List
  async getTotalBillNbh(reqObj: any) {
    const totalBillNbh$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketTimeLogs`,
      reqObj,
      true
    );
    let response = await lastValueFrom(totalBillNbh$);
    return response;
  }
  // Refersh Ticket(view/edit)
  async getParticularTicketFetch(obj: any) {
    const ticketFetch$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketDetailsByTicketCode`,
      obj,
      true
    );
    let response = await lastValueFrom(ticketFetch$);
    return response;
  }
  //Agent List
  async getAgentList(reqObj: any) {
    const agentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAgents`,
      reqObj,
      true
    );
    let response = await lastValueFrom(agentList$);
    return response;
  }

  async getAgentStatusList() {
    const agentList$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketAgentStatus`,
      {},
      true
    );
    let response = await lastValueFrom(agentList$);
    return response;
  }
  // get Proxy Users Service
  async getProxyUser(reqObj: any) {
    const proxyList$ = this.apiService.get(
      `${this.ticketBaseApi}/getProxyUsers`,
      reqObj,
      true
    );
    let response = await lastValueFrom(proxyList$);
    return response.body;
  }
  // get With Customer Service
  async withCustomer(custparam) {
    //console.log("LOGTYPE",logType);
    let logType = custparam.logType;
    let ticketCode = custparam.idTicketCode;
    const withCust$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketWorkLogComments`,
      { ticketCode, logType },
      true
    );
    let response = await lastValueFrom(withCust$);
    return response.body;
  } // get With LockTable
  async getLockTable(ticketCode) {
    console.log("service.>>>>", ticketCode);
    // let ticketCode = custparam.ticketCode;
    const lockTable$ = this.apiService.get(
      `${this.ticketBaseApi}/lockTicket`,
      { ticketCode },
      true
    );
    let response = await lastValueFrom(lockTable$);
    return response;
  } // get With UnLockTable
  async getUnLockTable(ticketCode) {
    //    console.log("unlock.>>>>",ticketCode);
    // let ticketCode = custparam.ticketCode;
    const lockTable$ = this.apiService.get(
      `${this.ticketBaseApi}/unlockTicket`,
      { ticketCode },
      true
    );
    let response = await lastValueFrom(lockTable$);
    return response;
  }
  // Create Ticket
  async createTicket(ticketData) {
    //saveTicket
    this.dialogData = ticketData;
    const newTicket$ = this.apiService.post(
      `${this.ticketBaseApi}/v1/saveTicket`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(newTicket$);
    console.log(response, "Success");
    return response;
  }
  /* Get Ticket list by searchcriteria */
  async getList(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasters`,
      searchCriteriaBody,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }

  async getTicketDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadTicketManagement`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }

  async getBillingDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadBillingReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getTicketViewList(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasterView`,
      searchCriteriaBody,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }

  async getTicketList(query, ticketSearchQuery) {
    let page = query.page;
    let size = query.size;
    //getHapTicketMasterView
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/v1/getHapTicketMasterView`,
      ticketSearchQuery,
      { page, size },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }
  async getMyTeamTargetBasedData() {
    const ticketList$ = this.apiService.get(
      `${this.ticketBaseApi}/getMyTeamTargetBasedData`,
      {},
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }

  async getRetrieveTimeBookingList(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketHoursBooking`,
      searchCriteriaBody,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }
  /* Get Reference Ticket list by searchcriteria */
  async getListRef(searchCriteria, searchCriteriaBody) {
    let page = searchCriteria.pageNo;
    let size = 0;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;

    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasters`,
      searchCriteriaBody,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }
  async getListdash(searchCriteria, dashboardcard) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasters`,
      dashboardcard,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }

  async getListdashboard(searchCriteria, dashboardcard) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketMasterView`,
      dashboardcard,
      { page, size, sort, sortByAscending },
      true
    );
    let response = await lastValueFrom(ticketList$);
    return response;
  }
  // Ticket Time Booking Service
  async timeBooking(bookingReq) {
    this.dialogData = bookingReq;
    const newTicket$ = this.apiService.post(
      `${this.ticketBaseApi}/saveTimeLog`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(newTicket$);
    console.log(response, "Success");
    return response;
  }

  /* Get Ticket Time Booked list by ticket code */
  async getTimeBookedList(reqObj) {
    const timeBooked$ = this.apiService.get(
      `${this.ticketBaseApi}/getTimeLogByTicketCode`,
      reqObj,
      true
    );
    let response = await lastValueFrom(timeBooked$);
    return response;
  }
  async getAllocatedHoursList(reqObj) {
    const timeAllocatedHrs$ = this.apiService.get(
      `${this.ticketBaseApi}/getHoursSpend`,
      reqObj,
      true
    );
    let response = await lastValueFrom(timeAllocatedHrs$);
    return response;
  }
  /* Get Ticket Card list by searchcriteria */
  async getCards(searchCriteriaBody) {
    const cardList$ = this.apiService.post(
      `${this.ticketBaseApi}/getTicketViewDashboardCards`,
      searchCriteriaBody,
      {},
      true
    );
    let response = await lastValueFrom(cardList$);
    return response;
  }

  async getDashboardCards(searchCriteriaBody) {
    const cardList$ = this.apiService.post(
      `${this.ticketBaseApi}/getDashboardCard`,
      searchCriteriaBody,
      {},
      true
    );
    let response = await lastValueFrom(cardList$);
    return response;
  }

  // get Refference Ticket Service
  async getRefTicket(reqObj: any) {
    const refTicketList$ = this.apiService.get(
      `${this.ticketBaseApi}/getReferanceTickets`,
      reqObj,
      true
    );
    let response = await lastValueFrom(refTicketList$);
    return response.body;
  }

  // Get Timsheet report(Dashboard)
  async getTimsheetReport(reqObj) {
    this.dialogData = reqObj;
    const addticketattach$ = this.apiService.post(
      `${this.ticketBaseApi}/getDetailedTimeSheetReport`,
      this.dialogData,
      "",
      true
    );
    let response = await lastValueFrom(addticketattach$);

    return response.body;
  }

  async getHapTicketAssetInTake(reqObj: any) {
    const assetIntake$ = this.apiService.get(
      `${this.ticketBaseApi}/getHapTicketAssetInTake`,
      reqObj,
      true
    );
    let response = await lastValueFrom(assetIntake$);
    return response.body;
  }
  async getHmbModeOfTransportMaster() {
    const transport$ = this.apiService.get(
      "/api/hap/master/getHmbModeOfTransportMaster",
      "",
      false
    );
    let response = await lastValueFrom(transport$);
    return response.body;
  }
  async getHapTicketAssetTravelExpense(reqObj: any) {
    const travelExpenses$ = this.apiService.get(
      `${this.ticketBaseApi}/getHapTicketAssetTravelExpense`,
      reqObj,
      true
    );
    let response = await lastValueFrom(travelExpenses$);
    return response;
  }
  //Asset Material List
  async getMaterialList(ticketCode: any) {
    const getMaterialList$ = this.apiService.get(
      "/api/nerve/ticket/getHapTicketPartReplacementByTicketCode",
      ticketCode,
      true
    );
    let response = await lastValueFrom(getMaterialList$);
    return response;
  }

  async getUOMList() {
    const uomList$ = this.apiService.get(`/api/admin/master/getUom`, {});
    let response = await lastValueFrom(uomList$);
    return response.body;
  }

  //Job Card List
  async getJobCardData(ticketCode: any) {
    const getJobCardList$ = this.apiService.get(
      "/api/nerve/ticket/getHapTicketJobCard",
      ticketCode,
      true
    );
    let response = await lastValueFrom(getJobCardList$);
    return response;
  }

  async generateJobCardData(reqObj) {
    const generateJobCard$ = this.apiService.get(
      "/api/nerve/ticket/generateJobCardByJobCardAndTicketCode",
      reqObj,
      true,
      "blob"
    );
    let response = await lastValueFrom(generateJobCard$);
    return response.body;
  }
  async getMappingEmployee(allUser) {
    ///api/nerve/ticket/getMappingEmployee Old
    ///api/nerve/ticket/v1/getMappingEmployee old

    const hubEmployeeList$ = this.apiService.get(
      `/api/nerve/ticket/v2/getMappingEmployee`,
      { allUser },
      true
    );
    let response = await lastValueFrom(hubEmployeeList$);
    return response.body;
  }

  async getComplaintAssetData(reqObj: any) {
    // '/api/hap/master/getCompliantNatureByAssetCode' // Old API
    // /api/nerve/ticket/v1/getCompliantNatureByAssetCode// Old API
    const complaintByAssetData$ = this.apiService.get(
      "/api/nerve/ticket/v4/getCompliantNatureByAssetCode",
      reqObj,
      true
    );
    let response = await lastValueFrom(complaintByAssetData$);
    return response.body;
  }

  async getHapAssetDetailsByAssetCodeAndDept(reqObj: any) {
    //getHapAssetDetailsByAssetCodeAndDept
    const equipmentNameData$ = this.apiService.get(
      "/api/nerve/ticket/v1/getHapAssetDetailsByAssetCodeAndDept",
      reqObj,
      true
    );
    let response = await lastValueFrom(equipmentNameData$);
    return response.body;
  }
  async getTicketAssetComplaints(reqObj: any) {
    const ticketWiseComplaintList$ = this.apiService.get(
      "/api/nerve/ticket/getHapTicketAssetCompliants",
      reqObj,
      true
    );
    let response = await lastValueFrom(ticketWiseComplaintList$);
    return response.body;
  }

  async getTicketHistory(reqObj: any) {
    const ticketHistory$ = this.apiService.get(
      `${this.ticketBaseApi}/getTicketHistoryByTicketCode`,
      reqObj,
      true
    );
    let response = await lastValueFrom(ticketHistory$);
    return response;
  }
  async downloadTicketStatusHistory(ticketCode) {
    console.log("apiii", ticketCode);
    const ticketHistory$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadTicketStatusHistory`,
      {},
      { ticketCode },
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketHistory$);
    return response.body;
  }
  async getMaterialsByAsset(assetCode: any) {
    // '/api/nerve/ticket/getMaterialsByAsset',
    const getMaterialList$ = this.apiService.get(
      // "/api/nerve/ticket/v3/getMaterialsByAsset",
      "/api/nerve/ticket/v4/getMaterialsByAsset",
      assetCode,
      true
    );
    let response = await lastValueFrom(getMaterialList$);
    return response;
  }
  //dept list dropdown
  async getListDepartment(searchCriteria) {
    let page = searchCriteria.pageNo;
    let size = searchCriteria.pageSize;
    let sort = searchCriteria.sortColumn;
    let sortByAscending = searchCriteria.sortOrder;
    const departmentList$ = this.apiService.post(
      "/api/admin/master/getDepartmentMasterList",
      {},
      { page, size, sort, sortByAscending },
      false
    );
    let response = await lastValueFrom(departmentList$);
    return response;
  }
  async getListProductMaster() {
    const productMasterList$ = this.apiService.post(
      "/api/admin/master/getProductMasterListByGNo",
      ""
    );
    let response = await lastValueFrom(productMasterList$);
    return response;
  }
  async getListTicketStatus() {
    const ticketStatusList$ = this.apiService.get(
      "/api/admin/master/getTicketStatusList",
      ""
    );
    let response = await lastValueFrom(ticketStatusList$);
    return response;
  }
  async getListTicketSubStatus() {
    const ticketsubStatusList$ = this.apiService.get(
      "/api/admin/master/getMasterTicketSubStatusList",
      ""
    );
    let response = await lastValueFrom(ticketsubStatusList$);
    return response;
  }
  /* Get Role list by searchcriteria */
  async getListRoleMaster(deptCode: any) {
    const roleMasterList$ = this.apiService.get(
      "/api/hap/master/getRolesByDept",
      { deptCode },
      false
    );
    let response = await lastValueFrom(roleMasterList$);
    return response;
  }
  /* Get Module list by searchcriteria */
  getListModule(productCode: any) {
    const abortController = new AbortController();
    return this.apiService
      .get(
        "/api/admin/master/getProductModuleListByProductCode",
        { productCode, signal: abortController.signal },
        false
      )
      .pipe(takeUntil(this.detailsDestroy$));
  }

  cancelProjectDetailsRequests(): void {
    this.detailsDestroy$.next();
    this.detailsDestroy$.complete();
    this.detailsDestroy$ = new Subject<void>();
  }
  //addProjectModuleStatus

  async addProjectModuleStatus(projectDetails) {
    const addProjectModuleStatus$ = this.apiService.post(
      "/api/hap/master/saveOrUpdateHapProjectModuleTicketSubStatus",
      projectDetails
    );
    let response = await lastValueFrom(addProjectModuleStatus$);

    return response;
  }
  async addDeptRoleMapping(projectDetails) {
    const deptRoleMapp$ = this.apiService.post(
      "/api/hap/master/saveOrUpdateHapProjectDeptProductModuleRoleMapping",
      projectDetails
    );
    let response = await lastValueFrom(deptRoleMapp$);
    return response;
  }
  async getProjectList(searchCriteria) {
    const projectList$ = this.apiService.post(
      "/api/admin/master/getAllMastersByGNo?countryCode=" +
        searchCriteria.countryCode,
      searchCriteria
    );
    let response = await lastValueFrom(projectList$);
    return response;
  }

  //get Ticket Count

  async getTicketCountList(ticketSearchQuery) {
    const ticketCountList$ = this.apiService.post(
      `${this.ticketBaseApi}/countHapTicketMasters`,
      ticketSearchQuery,
      {},
      true
    );
    let response = await lastValueFrom(ticketCountList$);
    return response;
  }
  //New Reports For HAP
  async getComplaintForwardHub(getData) {
    this.dialogData = getData;
    const List$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/getMSPComplaintVsForwardHub`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  async getComplaintForwardHubExcel(searchCriteriaBody) {
    const excel$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadMSPComplaintVsForwardHub`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(excel$);
    console.log("response>>>", response);
    return response.body;
  }
  async getMspAgingReport(getData) {
    this.dialogData = getData;
    const List$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/getMSPWiseAgingReport`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  async getForwardToHub(getData) {
    this.dialogData = getData;
    const List$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/getMspHandledAndHubHandledReport`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  async getEkoComplaint(getData) {
    this.dialogData = getData;
    const List$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/getMSPWiseAssignedVsCompliantResponse`,
      this.dialogData,
      {},
      true
    );
    let response = await lastValueFrom(List$);
    return response;
  }
  async getAgingDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadgetMSPWiseAgingReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getEkoComplaintDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadgetMSPWiseAssignedVsCompliantReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  async getForwardHubDownload(searchCriteriaBody) {
    const ticketList$ = this.apiService.post(
      `${this.ticketBaseApi}/excel/downloadgetMspHandledAndHubHandledReport`,
      searchCriteriaBody,
      "",
      true,
      "blob"
    );
    let response = await lastValueFrom(ticketList$);
    console.log("response>>>", response);
    return response.body;
  }
  //own asset verify
  async updateAssetVerify(obj) {
    //
    let req = obj;
    const assetVerify$ = this.apiService.post(
      `${this.ticketBaseApi}/overAllApproveOwnAsset`,
      req,
      "",
      true
    );
    let response = await lastValueFrom(assetVerify$);

    return response;
  }

  // Save asset tracker
  async saveAssetTracker(tracker) {
    const trackerList$ = this.apiService.post(
      `${this.ticketBaseApi}/saveAssetTracker`,
      tracker,
      {},
      true
    );
    let response = await lastValueFrom(trackerList$);
    return response;
  }
  /* Save AIC
   *  */
  uploadRecoveryFileAttachment(formData) {
    const abortController = new AbortController();
    return this.apiService
      .post(
        "/api/nerve/ticket/uploadRecoveryFileAttachment",
        formData,
        { signal: abortController.signal },
        true
      )
      .pipe(takeUntil(this.detailsDestroy$));
  }
  /* Get AIC
   *  */
  getRecoveryFileAttachment(req) {
    return this.apiService
      .get("/api/nerve/ticket/getHapTicketRecoveryFileAttachments", req, true)
      .pipe(takeUntil(this.detailsDestroy$));
  }
}
